import { Button, Container, Grid, Link } from '@mui/material'
import { CoreAlert, CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'

interface IPermissionsRequestScreenProps {
    onClick: () => void
    tempUserId: string
}

const PermissionsRequestScreen: React.FC<IPermissionsRequestScreenProps> = ({
    onClick,
    tempUserId
}) => {
    return (
        <Container maxWidth="sm">
            <Grid
                container
                direction="column"
                justifyContent="center"
                style={{ height: '100vh' }}
            >
                <Grid item>
                    <CoreTypography variant="h1">
                        <FormattedMessage
                            defaultMessage="Almost there. We need to request permissions."
                            description="Heading shown when we need to request more permissions"
                        />
                    </CoreTypography>
                </Grid>
                <Grid item>
                    <CoreTypography variant={'body1'}>
                        <FormattedMessage
                            defaultMessage="In order to use Thrive we need to request permissions to use the app. Press Continue to request the permissions on the behalf of Thrive."
                            description="Message explaining that we will request permissions"
                        />
                    </CoreTypography>
                </Grid>
                <Grid item>
                    <Button
                        onClick={onClick}
                        variant="contained"
                        sx={{ margin: 2 }}
                    >
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="Continue"
                                description="Button used to request permissions"
                            />
                        </CoreTypography>
                    </Button>
                </Grid>
                <Grid item>
                    <CoreAlert severity="info">
                        <FormattedMessage
                            defaultMessage="Message code: {code}"
                            description="Error code text"
                            values={{
                                code: tempUserId
                            }}
                        />
                    </CoreAlert>
                </Grid>
            </Grid>
        </Container>
    )
}

export default PermissionsRequestScreen
