import { Container, Grid, Link } from '@mui/material'
import { CoreAlert, CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import {
    FormattedMessage as _FormattedMessage,
    defineMessages,
    useIntl
} from 'react-intl'
import { ModifiedFormattedMessageType } from '../types'

const FormattedMessage =
    _FormattedMessage as unknown as ModifiedFormattedMessageType

interface TeamsAuthErrorScreenProps {
    errorMessage: string
}

const messages = defineMessages({
    teamsSupportSubject: {
        defaultMessage: 'Thrive for Teams Support Request',
        description: 'Subject line for support email'
    },
    salesSubject: {
        defaultMessage: 'Thrive for Teams Sales Request',
        description: 'Subject line for sales email'
    }
})

const TeamsAuthErrorScreen: React.FC<TeamsAuthErrorScreenProps> = ({
    errorMessage
}) => {
    const intl = useIntl()
    return (
        <Container maxWidth="sm" data-testid="teams-auth-error-screen">
            <Grid
                container
                direction="column"
                justifyContent="center"
                style={{ height: '100vh' }}
            >
                <Grid item>
                    <CoreTypography variant={'h1'}>
                        <FormattedMessage
                            defaultMessage="Something's not quite right."
                            description="Generic error heading for teams auth error"
                        />
                    </CoreTypography>
                </Grid>
                <Grid item>
                    <CoreTypography variant={'body1'}>
                        <FormattedMessage
                            defaultMessage="Come back soon and try again. In the meantime, you can still use Thrive via the Chat tab."
                            description="error instructions for teams auth error"
                        />
                    </CoreTypography>
                </Grid>
                <Grid item>
                    <CoreTypography variant={'body1'}>
                        <FormattedMessage
                            defaultMessage="If you're still seeing this message in a few hours, you can reach out to us at <emailLink>support@thriveglobal.com</emailLink>. Please send a screenshot of this page or paste in the following error code:"
                            description="Text promoting user to contact support"
                            values={{
                                emailLink: (chunk) => (
                                    <Link
                                        href={`mailto:support@thriveglobal.com?subject=${intl.formatMessage(
                                            messages.teamsSupportSubject
                                        )}`}
                                    >
                                        {chunk}
                                    </Link>
                                )
                            }}
                        >
                            {(chunks) => <span>{chunks}</span>}
                        </FormattedMessage>
                    </CoreTypography>
                </Grid>
                <Grid item>
                    <CoreAlert severity="error">
                        <FormattedMessage
                            defaultMessage="Error code: {code}"
                            description="Error code text"
                            values={{
                                code: errorMessage
                            }}
                        />
                    </CoreAlert>
                </Grid>
                <Grid item>
                    <CoreTypography variant={'body1'}>
                        <FormattedMessage
                            defaultMessage="Trying to purchase Thrive? Reach out to <emailLink>sales@thriveglobal.com</emailLink>."
                            description="Text promoting user to contact support"
                            values={{
                                emailLink: (chunk: React.ReactNode[]) => (
                                    <Link
                                        href={`mailto:sales@thriveglobal.com?subject=${intl.formatMessage(
                                            messages.salesSubject
                                        )}`}
                                    >
                                        {chunk}
                                    </Link>
                                )
                            }}
                        >
                            {(chunks) => <span>{chunks}</span>}
                        </FormattedMessage>
                    </CoreTypography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default TeamsAuthErrorScreen
