import { captureException } from '@thriveglobal/thrive-web-core'

const THRIVE_TEMP_USER_ID = 'Thrive-temp-user-id'

export function generateUserId(): string {
    const max = Number?.MAX_SAFE_INTEGER || 100
    const randomInt = Math.floor(Math.random() * (max - 0 + 1)) + 0
    return randomInt.toString(16)
}

export function saveUserIdInStorage(userId: string) {
    if (!userId) {
        return
    }
    try {
        localStorage.setItem(THRIVE_TEMP_USER_ID, userId)
    } catch (error) {
        captureException(error, {
            message: 'Could not set temp user id in storage'
        })
    }
}

export function getUserIdFromStorage(): string {
    try {
        return localStorage.getItem(THRIVE_TEMP_USER_ID)
    } catch (error) {
        captureException(error, {
            message: 'Could not get temp user id from storage'
        })
        return null
    }
}

export function getOrGenerateUserId() {
    const previousUserId = getUserIdFromStorage()
    if (!previousUserId) {
        const userId = generateUserId()
        saveUserIdInStorage(userId)
        return userId
    }
    return previousUserId
}
