/* eslint-disable no-console */
import { DD, store, ThriveApplication } from '@thriveglobal/thrive-web-core'
import React, { ComponentProps, useEffect } from 'react'
import { Provider } from 'react-redux'
import ThemedTeamsAuth from './Providers/ThemedTeamsAuth'
import { getOrGenerateUserId } from './utils/getTempUserId'

type SentryConfig = ComponentProps<typeof ThriveApplication>['sentryOptions']

export const sentryOptions: SentryConfig = {
    dsn: 'https://85de398a85644f8ea8a17da1903c72b0@o59726.ingest.sentry.io/6112100'
}

function loadi18n(lang: string) {
    return fetch(`${process.env.CF_SOURCE_URL}/lang/${lang}.json`)
        .then((res) => res.json())
        .catch(console.warn)
}

export default function Root(props) {
    useEffect(() => {
        const userId = getOrGenerateUserId()
        DD.addUnAuthenticatedUserId(userId)
        return () => {
            DD.removeUnAuthenticatedUserId()
        }
    }, [])

    return (
        <ThriveApplication
            messagesLoaders={[loadi18n]}
            sentryOptions={sentryOptions}
            apolloClientOptions={{
                name: 'thrive-web-ms-teams-auth',
                cacheOptions: {}
            }}
        >
            <Provider store={store}>
                <section>
                    <ThemedTeamsAuth />
                </section>
            </Provider>
        </ThriveApplication>
    )
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
