/* eslint-disable no-console */
import { CssBaseline, ThemeProvider } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import TeamsAuthProvider from './TeamsAuthProvider'

const ThemedTeamsAuth = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <TeamsAuthProvider />
        </ThemeProvider>
    )
}

export default ThemedTeamsAuth
