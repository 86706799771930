import * as React from 'react'
import * as ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import { captureException } from '@thriveglobal/thrive-web-core'
import { auth } from '@thriveglobal/thrive-web-auth-core'

auth.setAuthType('azuread')

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err, info, props) {
        captureException(err, {
            info,
            props,
            message: 'Ms Teams Auth Error boundary called'
        })
        return null
    }
})

export const { bootstrap, mount, unmount } = lifecycles
