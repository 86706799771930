export function forcedTimeout<T>(timeout: number, callback: () => Promise<T>) {
    return new Promise<T>((resolve, reject) => {
        const timer = setTimeout(() => {
            reject(new Error(`Promise timed out after ${timeout} ms`))
        }, timeout)

        callback()
            .then((result) => {
                resolve(result)
            })
            .catch((error) => {
                reject(error)
            })
            .finally(() => {
                clearTimeout(timer)
            })
    })
}

export function retry<T, K>(
    callback: () => Promise<T>,
    onSuccess: (result: T) => Promise<K>,
    onError: (error: any, reload: boolean, retryCount: number) => boolean,
    loadRetry: () => number,
    increaseRetry: () => void
) {
    const retryCount = loadRetry()

    return callback()
        .then(onSuccess)
        .catch((error) => {
            const reload = retryCount < 15

            const canReload = onError(error, reload, retryCount)

            if (reload && canReload) {
                increaseRetry()
                window.location.reload()
            }

            return Promise.reject(error)
        })
}

export function retryForcedTimeout<T, K>(
    timeout: number,
    callback: () => Promise<T>,
    onSuccess: (result: T) => Promise<K>,
    onError: (error: any, reload: boolean, retryCount: number) => boolean,
    loadRetry: () => number,
    increaseRetry: () => void
) {
    const retryCount = loadRetry()

    return retry<T, K>(
        () =>
            forcedTimeout<T>(
                timeout * (!retryCount ? 1 : retryCount),
                callback
            ),
        onSuccess,
        onError,
        loadRetry,
        increaseRetry
    )
}
