import { Box, Button, Link, Stack } from '@mui/material'
import {
    CoreTypography,
    PageLayout,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { useMemo } from 'react'
import {
    FormattedMessage as _FormattedMessage,
    defineMessages,
    useIntl
} from 'react-intl'
import { ModifiedFormattedMessageType } from '../types'

const FormattedMessage =
    _FormattedMessage as unknown as ModifiedFormattedMessageType

const messages = defineMessages({
    teamsSupportSubject: {
        defaultMessage: 'Thrive for Teams Support Request',
        description: 'Subject line for support email'
    },
    salesSubject: {
        defaultMessage: 'Thrive for Teams Sales Request',
        description: 'Subject line for sales email'
    }
})

const ThrivexTeams =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/78eda7b4-d3c0-42d1-a981-cfcd7cf2bf00/cms'

const NotConfiguredMarketingScreen: React.FC = () => {
    const { formatMessage } = useIntl()
    const { breakpoints, gradient } = useTheme()
    const teamsBackground = useMemo(
        () => ({
            backgroundImage: `url(${ThrivexTeams})`,
            backgroundPosition: '100% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
        }),
        []
    )

    return (
        <Box
            data-testid="not-configured-marketing-screen"
            sx={{
                width: '100vw',
                height: '100vh',
                background: gradient.main,
                overflow: 'auto'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    [breakpoints.down('lg')]: {
                        backgroundPosition: 'calc(100% + 100px) 100%'
                    },
                    [breakpoints.up('md')]: {
                        ...teamsBackground
                    }
                }}
            >
                <PageLayout maxWidth="md" style={{ height: '100%' }}>
                    <Stack
                        gap={2}
                        maxWidth="412px"
                        height="100%"
                        minHeight="470px"
                        py={10}
                        sx={{
                            justifyContent: 'center',
                            [breakpoints.down('md')]: {
                                mx: 'auto'
                            }
                        }}
                    >
                        <CoreTypography variant="h1" color="primary.main">
                            <FormattedMessage
                                defaultMessage="Fuel Productivity through Behavior Change"
                                description="splash screen title"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1" color="text.primary">
                            <FormattedMessage
                                defaultMessage="Empower your organization with Thrive’s all-in-one productivity and health platform. To get a demo, reach out to <emailLink>sales@thriveglobal.com</emailLink>."
                                description="splash screen description"
                                values={{
                                    emailLink: (chunk) => (
                                        <Link
                                            href={`mailto:sales@thriveglobal.com?subject=${formatMessage(
                                                messages.teamsSupportSubject
                                            )}`}
                                        >
                                            {chunk}
                                        </Link>
                                    )
                                }}
                            />
                        </CoreTypography>
                        <Box>
                            <Button
                                onClick={() =>
                                    window.open(
                                        'https://thriveglobal.com/',
                                        '_blank'
                                    )
                                }
                                size="large"
                                variant="contained"
                            >
                                <CoreTypography customVariant="buttonLarge">
                                    <FormattedMessage
                                        defaultMessage="Learn more"
                                        description="lead more button"
                                    />
                                </CoreTypography>
                            </Button>
                        </Box>
                    </Stack>
                </PageLayout>
            </Box>
        </Box>
    )
}

export default NotConfiguredMarketingScreen
