import { loadState, saveState } from '@thriveglobal/thrive-web-core'

export enum RetryStorageEvents {
    RETRY_MS_CONTEXT = 'RETRY_MS_CONTEXT',
    RETRY_MS_AUTH = 'RETRY_MS_AUTH'
}

export function loadDefaultState(event: RetryStorageEvents): number {
    return loadState(event) ?? 0
}

export function loadRetryContextState(): number {
    return loadDefaultState(RetryStorageEvents.RETRY_MS_CONTEXT)
}

export function loadRetryAuthState(): number {
    return loadDefaultState(RetryStorageEvents.RETRY_MS_AUTH)
}

export function saveRetryContextState(retryCount: number) {
    saveState(RetryStorageEvents.RETRY_MS_CONTEXT, retryCount)
}

export function saveRetryAuthState(retryCount: number) {
    saveState(RetryStorageEvents.RETRY_MS_AUTH, retryCount)
}

export function deleteRetryContextState() {
    saveState(RetryStorageEvents.RETRY_MS_CONTEXT, null)
}

export function deleteRetryAuthState() {
    saveState(RetryStorageEvents.RETRY_MS_AUTH, null)
}

export function increaseRetryCount(
    loadRetryState: () => number,
    saveRetryState: (retryCount: number) => void
) {
    const currentState = loadRetryState()
    const newRetryCount = currentState ? currentState + 1 : 1
    saveRetryState(newRetryCount)
}

export function increaseRetryContextCount() {
    increaseRetryCount(loadRetryContextState, saveRetryContextState)
}

export function increaseRetryAuthCount() {
    increaseRetryCount(loadRetryAuthState, saveRetryAuthState)
}
