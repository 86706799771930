import { app } from '@microsoft/teams-js'
import {
    captureException,
    captureMessage,
    isFeatureEnabled,
    loadState,
    setTheme,
    store,
    useCrossAppNavigation
} from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getParamRelay } from '../../utils/getParamRelay'
import { getThemeFromMsTeams } from '../../utils/getThemeFromMsTeams'
import {
    deleteRetryContextState,
    increaseRetryContextCount,
    loadRetryContextState
} from '../../utils/manageRetryStatesLocally'
import { retry } from '../../utils/promiseRetry'
import {
    StorageEvents,
    tokenExchange,
    tokenExchangeWithTokenWithExtraPermissions
} from '../../utils/tokenExchange'

const onInitializeSuccess = () => {
    return Promise.resolve(true)
}

export const useMsTeamsAuth = (
    tempUserId: string,
    isAlreadyLoggedIn: boolean
) => {
    const authTriggered = useRef(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [reload, setReload] = useState(false)
    const [errorVariant, setErrorVariant] = useState({
        variant: null, // needs to be set to type ErrorScreenVariant | null
        reason: ''
    })
    const [isLoggedIn, setIsLoggedIn] = useState(isAlreadyLoggedIn || false)
    const [context, setContext] = useState<app.Context>()
    const [showPermissionRequestScreen, setShowPermissionRequestScreen] =
        useState(false)
    const navigate = useCrossAppNavigation()

    const initializeTeamsApp = useCallback<() => Promise<boolean>>(() => {
        if (app.isInitialized()) {
            return onInitializeSuccess()
        }

        return retry<void, boolean>(
            app.initialize,
            onInitializeSuccess,
            (error, reload, retryCount) => {
                setReload(reload)
                captureException(error, {
                    message: `Failed to initialize Teams JS app, retry ${retryCount}`,
                    tempUserId
                })

                return true
            },
            loadRetryContextState,
            increaseRetryContextCount
        )
    }, [tempUserId])

    const setMsTeamsTheme = useCallback<(context: app.Context) => void>(
        (context) => {
            try {
                store.dispatch(setTheme(getThemeFromMsTeams(context.app.theme)))
            } catch (error) {
                captureException(error, {
                    message: 'Failed to set MS Teams Theme'
                })
                store.dispatch(setTheme(getThemeFromMsTeams('')))
            }
        },
        []
    )

    const getMsTeamsContext = useCallback<() => Promise<app.Context>>(() => {
        return retry<app.Context, app.Context>(
            app.getContext,
            (context) => {
                deleteRetryContextState()
                setContext(context)
                setMsTeamsTheme(context)

                return Promise.resolve(context)
            },
            (error, reload, retryCount) => {
                setReload(reload)
                captureException(error, {
                    message: `Failed to get Teams JS Context, retry ${retryCount}`,
                    tempUserId
                })

                return true
            },
            loadRetryContextState,
            increaseRetryContextCount
        )
    }, [setMsTeamsTheme, tempUserId])

    const reRoute = useCallback(
        (context: app.Context) => {
            const deepLinkPath = context?.page?.subPageId
            const relayPath = deepLinkPath ? deepLinkPath : getParamRelay()
            captureMessage(`Ms Teams rerouting to relay path ${relayPath}`)
            navigate(`/${relayPath}`)
        },
        [navigate]
    )

    const triggerAuthFlow = useCallback(() => {
        setLoading(true)

        return initializeTeamsApp()
            .then(getMsTeamsContext)
            .then(async (context) => {
                const reuseTokenInMsTeams = await isFeatureEnabled(
                    'reuseTokenInMsTeams',
                    false,
                    true,
                    { tenantId: context?.user?.tenant?.id }
                )
                const userTenantId = loadState(
                    StorageEvents.THRIVE_MS_TENANT_ID
                )

                if (
                    !reuseTokenInMsTeams ||
                    !isAlreadyLoggedIn ||
                    userTenantId !== context.user.id
                ) {
                    return tokenExchange(
                        setIsLoggedIn,
                        setErrorVariant,
                        setReload,
                        setShowPermissionRequestScreen,
                        context,
                        tempUserId
                    )
                } else {
                    captureMessage(
                        'MS Teams user is already logged in, reusing token'
                    )
                    setIsLoggedIn(true)
                    app.notifyAppLoaded()
                    app.notifySuccess()
                    reRoute(context)
                }
            })
            .catch(() => {
                setError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [
        initializeTeamsApp,
        getMsTeamsContext,
        reRoute,
        isAlreadyLoggedIn,
        tempUserId
    ])

    const onTriggerTokenExchangeWithExtraPermissions = useCallback(() => {
        setShowPermissionRequestScreen(false)
        tokenExchangeWithTokenWithExtraPermissions(
            setIsLoggedIn,
            setErrorVariant,
            context,
            tempUserId
        )
    }, [context, tempUserId])

    useEffect(() => {
        if (!authTriggered.current) {
            authTriggered.current = true
            triggerAuthFlow()
        }
    }, [authTriggered, triggerAuthFlow])

    return {
        isLoggedIn,
        loading,
        reload,
        error,
        errorVariant,
        showPermissionRequestScreen,
        onTriggerTokenExchangeWithExtraPermissions
    }
}

export default useMsTeamsAuth
