import {
    LoginCallback,
    selectAreTokensStillValidReason,
    selectIsLoggedIn,
    selectIsUsingIdentityAuthAndTokensStillValid
} from '@thriveglobal/thrive-web-auth-core'
import {
    ErrorScreen,
    ErrorScreenVariant
} from '@thriveglobal/thrive-web-leafkit'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { Suspense } from 'react'
import NotConfiguredMarketingScreen from '../auth/NotConfiguredMarketingScreen'
import PermissionsRequestScreen from '../auth/PermissionsRequestScreen'
import TeamsAuthErrorScreen from '../auth/TeamsAuthErrorScreen'
import useMsTeamsAuth from '../hooks/useMsTeamsAuth/useMsTeamsAuth'
import { getOrGenerateUserId } from '../utils/getTempUserId'

const SPECIAL_ERROR_VARIANTS = ['AuthError']

const TeamsAuthProvider = () => {
    const tempUserId = getOrGenerateUserId()
    const authStore = useAppSelector((state) => state.auth)
    const isAlreadyLoggedIn: boolean = selectIsLoggedIn(authStore)
    const isTokenStillValid =
        selectIsUsingIdentityAuthAndTokensStillValid(authStore)
    const {
        isLoggedIn,
        loading,
        error,
        reload,
        errorVariant,
        showPermissionRequestScreen,
        onTriggerTokenExchangeWithExtraPermissions
    } = useMsTeamsAuth(tempUserId, isAlreadyLoggedIn && isTokenStillValid)

    if (loading || reload) {
        return <></>
    }

    if (SPECIAL_ERROR_VARIANTS.includes(errorVariant.variant)) {
        return <TeamsAuthErrorScreen errorMessage={tempUserId} />
    }

    if (errorVariant.variant === 'NotConfigured') {
        return <NotConfiguredMarketingScreen />
    }

    if (errorVariant.variant) {
        return <ErrorScreen variant={errorVariant.variant} />
    }

    if (error) {
        return <ErrorScreen variant={ErrorScreenVariant.General} />
    }

    if (showPermissionRequestScreen) {
        return (
            <PermissionsRequestScreen
                onClick={onTriggerTokenExchangeWithExtraPermissions}
                tempUserId={tempUserId}
            />
        )
    }

    if (isLoggedIn) {
        return (
            <Suspense fallback={<></>}>
                <LoginCallback />
            </Suspense>
        )
    }

    return <></>
}

export default TeamsAuthProvider
