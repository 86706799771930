import { app } from '@microsoft/teams-js'
import * as msal from '@azure/msal-browser'
import { captureException } from '@thriveglobal/thrive-web-core'

export async function requestTokenWithExtraPermissions(
    context: app.Context
): Promise<string | null> {
    try {
        const scope = 'User.Read email openid profile offline_access'

        const msalConfig = {
            auth: {
                clientId: process.env.APP_ID,
                authority: `https://login.microsoftonline.com/${context.user.tenant.id}`,
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: 'sessionStorage'
            }
        }

        const msalInstance = new msal.PublicClientApplication(msalConfig)
        try {
            await msalInstance.initialize()
        } catch (error) {
            captureException(error, {
                message: 'Failed to initialize msalInstance, returning early'
            })
            return null
        }

        const scopesArray = scope.split(' ')
        const scopesRequest = {
            scopes: scopesArray
        }
        const res = await msalInstance.loginPopup(scopesRequest)
        return res?.accessToken
    } catch (error) {
        captureException(error, {
            message: 'Failed to request MS token with extra permissions'
        })
        return null
    }
}
