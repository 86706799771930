import { THEME } from '@thriveglobal/thrive-web-leafkit'

export function getThemeFromMsTeams(theme: string) {
    switch (theme) {
        case 'contrast':
            return THEME.HIGH_CONTRAST
        default:
            return THEME.THRIVE
    }
}
